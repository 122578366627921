import React, { useState, useEffect } from "react";

import { BREAKPOINTS } from "../../constants";
import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";
import { ROUTES } from "./contants";
import { useGetStaticPagesQuery } from "../../services/settingApi";
import { setSettingInfo } from "../../features/settingSlice";
import { useDispatch } from "react-redux";

export default function Setting() {
  const dispatch = useDispatch();
  const [route, setRoute] = useState(ROUTES.CHANGE_PASSWORD);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= BREAKPOINTS.MOBILE
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => window.removeEventListener("resize", handleWindowSizeChange);
  });

  function handleWindowSizeChange() {
    if (!isMobile && window.innerWidth <= BREAKPOINTS.MOBILE) {
      setRoute("");
    } else if (isMobile && window.innerWidth > BREAKPOINTS.MOBILE) {
      setRoute(ROUTES.EDIT_PROFILE);
    }
    setIsMobile(window.innerWidth <= BREAKPOINTS.MOBILE);
  }

  const { data, isSuccess } = useGetStaticPagesQuery({
    refetchOnMountOrArgChange: true,
  });

  console.log(data);

  useEffect(() => {
    if (data && isSuccess) {
      if (data.code === 200) {
        dispatch(
          setSettingInfo({
            terms: data.data.TERMS.description,
            terms_link: data.data.TERMS.link,
            privacy: data.data.PRIVACY.description,
            privacy_link: data.data.PRIVACY.link,
            email: data.data.SETTINGS.contact_email_us,
            per_day_post: data.data.SETTINGS.per_day_post,
            price: data.data.SETTINGS.price,
          })
        );
      }
    }
  }, [isSuccess, data, dispatch]);

  return isMobile ? (
    <MobileLayout route={route} setRoute={setRoute} />
  ) : (
    <DesktopLayout route={route} setRoute={setRoute} />
  );
}
