import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { ShimmerTable } from "react-shimmer-effects";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import EditUserModal from "../../Components/Modals/EditUserModal";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/user_management.module.scss";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { updateUsers } from "../../features/userSlice";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useUpdateUserMutation,
} from "../../services/userApi"; // get data from API Servers

const UserManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllUsersQuery({ refetchOnMountOrArgChange: true });

  var userData = useSelector((state) => state.user.users);

  // console.log(`userData = ${userData}`);

  useEffect(() => {
    setRowData(userData);
    // console.log(rowData);
  }, [userData]);

  useEffect(() => {
    if (data && isSuccess) {
      // console.log("isSuccess = " + data.data);
      dispatch(updateUsers(data.data));
    }
  }, [isSuccess, data, dispatch]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const [deleteUser] = useDeleteUserMutation();

  const deleteData = async () => {
    console.log(editedData.slug);

    const userSlug = editedData.slug;

    try {
      const { data } = await deleteUser(userSlug);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const [updateUser] = useUpdateUserMutation();

  const handleUpdate = async (formData) => {
    const userSlug = editedData.slug;

    const modifiedData = {
      status: formData.status === "active" ? true : false,
      slug: userSlug,
    };

    console.log(modifiedData);

    try {
      const { data } = await updateUser(modifiedData);
      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={6} />;
  }

  const columns = [
    {
      dataField: "username",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "300px",
      },
    },
    {
      dataField: "win_count", //todo:
      text: "Win Streak",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return <span>🔥 {row.win_count}</span>;
      },
    },
    {
      dataField: "amount", //todo:
      text: "Win Money",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return <span>$ {row.amount}</span>;
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.status === true)
          return <div className={styles.active}> Active </div>;
        if (row.status === false)
          return <div className={styles.block}>Block</div>;
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              {
                <div
                  className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
                  role="button"
                  onClick={() => {
                    console.log(row);
                    setEditedData(row);
                    handleEditModalOpen(true);
                  }}
                >
                  <span
                    className={`align-items-center d-flex justify-content-center pe-1 `}
                  >
                    <FaUserEdit />
                  </span>
                  <span>Edit</span>
                </div>
              }
              <div
                className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  console.log(row);
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
        />
      ) : (
        ""
      )}

      {editModal ? (
        <EditUserModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleUpdate={handleUpdate}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"User Management"} />
        </div>
        {
          //   <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add User"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default UserManagementScreen;
