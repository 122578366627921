import React, { useState } from 'react'

import { ROUTES } from "./Route";
import Login from '../Account/Login';
import SendNotificationScreen from './SendNotificationScreen';

export default function UserManagement() {

    const [page, setPage] = useState(ROUTES.SEND_NOTIFICATION)

    switch (page) {
        case ROUTES.SEND_NOTIFICATION:
            return <SendNotificationScreen setPage={setPage} />
        default:
            return <Login />
    }


}
