import { createSlice } from "@reduxjs/toolkit";
import { postApi } from "../services/postApi";

const initialState = {
  posts: [],
  reportPost: [],
};

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    updatePosts: (state, action) => {
      // console.log('update Users' + action.payload);
      state.posts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        postApi.endpoints.getAllReportPosts.matchFulfilled,
        (state, { payload }) => {
          state.reportPost = payload.data;
        }
      )
      .addMatcher(
        postApi.endpoints.getAllReportPosts.matchRejected,
        (state) => {
          state.reportPost = null;
        }
      );
  },
});

export const { updatePosts } = postSlice.actions;

export default postSlice.reducer;
