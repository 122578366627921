import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  terms: "",
  terms_link: "",
  privacy: "",
  privacy_link: "",
  email: "",
  per_day_post: "",
  price: "",
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSettingInfo: (state, action) => {
      state.terms = action.payload.terms;
      state.terms_link = action.payload.terms_link;
      state.privacy = action.payload.privacy;
      state.privacy_link = action.payload.privacy_link;
      state.email = action.payload.email;
      state.per_day_post = action.payload.per_day_post;
      state.price = action.payload.price;
    },
    unsetSettingInfo: (state, action) => {
      state.terms = action.payload.terms;
      state.terms_link = action.payload.terms;
      state.privacy = action.payload.privacy;
      state.privacy_link = action.payload.terms;
      state.email = action.payload.email;
      state.per_day_post = action.payload.per_day_post;
      state.price = action.payload.price;
    },
  },
});

export const { setSettingInfo } = settingSlice.actions;

export default settingSlice.reducer;
