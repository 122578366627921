import { createSlice } from '@reduxjs/toolkit'
import { userAuthApi } from '../services/userAuthApi';
import { storeToken } from '../services/localStorageService';
import { SuccessToast } from '../Hooks/useToast';

const initialState = {
  api_token: '',
  email: '',
  image_url: '',
  name: '',
  slug: '',
  status: 'init',
}

export const authSlice = createSlice({
  name: 'authInfo',
  initialState,
  reducers: {
    setAuthInfo: (state, action) => {
      state.api_token = action.payload.api_token;
      state.email = action.payload.email;
      state.image_url = action.payload.image_url;
      state.name = action.payload.name;
      state.slug = action.payload.slug;
    },
    unsetAuthInfo: (state, action) => {
      state.api_token = action.payload.api_token;
      state.email = action.payload.email;
      state.image_url = action.payload.image_url;
      state.name = action.payload.name;
      state.slug = action.payload.slug;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(userAuthApi.endpoints.loginUser.matchFulfilled, (state, { payload }) => {
      state.api_token = payload.data.api_token;
      state.email = payload.data.email;
      state.image_url = payload.data.image_url;
      state.name = payload.data.name;
      state.slug = payload.data.slug;
      state.status = 'succeeded';
    })
      .addMatcher(userAuthApi.endpoints.loginUser.matchRejected, (state) => {
        state.api_token = '';
        state.email = '';
        state.image_url = '';
        state.name = '';
        state.slug = '';
        state.status = 'failed';
      });
  }
})

export const { setAuthInfo, unsetAuthInfo } = authSlice.actions

export default authSlice.reducer