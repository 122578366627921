import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { MdDeleteForever } from "react-icons/md";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import DeleteModal from "../../Components/Modals/DeleteModal";
import { updatePosts } from "../../features/postSlice";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { useDeletePostMutation } from "../../services/onlyDeletePostApi";
import { useGetAllPostsQuery } from "../../services/postApi";

const PostManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);

  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllPostsQuery({ refetchOnMountOrArgChange: true });

  var postData = useSelector((state) => state.post.posts);

  useEffect(() => {
    setRowData(postData);
  }, [postData]);

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(updatePosts(data.data));
    }
  }, [isSuccess, data, dispatch]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const [deletePost] = useDeletePostMutation();

  const deleteData = async () => {
    console.log(editedData.slug);

    const postSlug = editedData.slug;

    try {
      const { data } = await deletePost(postSlug);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={3} />;
  }

  const columns = [
    {
      dataField: "pet_image",
      text: "Post Image",
      // sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return <img alt="post" src={row.pet_image} width={"150"} />;
      },
    },
    {
      dataField: "created_by",
      text: "User",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "like_count", //todo:
      text: "Vote",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              {
                // <div
                //   className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
                //   role="button"
                //   onClick={() => {
                //     console.log(row);
                //     setEditedData(row);
                //     handleEditModalOpen(true);
                //   }}
                // >
                //   <span
                //     className={`align-items-center d-flex justify-content-center pe-1 `}
                //   >
                //     <FaUserEdit />
                //   </span>
                //   <span>Edit</span>
                // </div>
              }
              <div
                className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  console.log(row);
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
        />
      ) : (
        ""
      )}
      {
        // editModal ? (
        //   <EditEventModal
        //     handleEditModalClose={handleEditModalClose}
        //     data={editedData}
        //   />
        // ) : (
        //   ""
        // )
      }

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Post Management"} />
        </div>
        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add Event"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {
          // <Table data={rowData} columns={columns} indication={indication} />
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default PostManagementScreen;
