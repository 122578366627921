const baseRoute = (name = '') => {
    return `${process.env.REACT_APP_DOMAIN_DIR}/${name}`
}

const apiBaseUrl = () => {
    return process.env.REACT_APP_API_DOMAIN + 'api/user/'
}

const localStorageToken = () => {
    return process.env.REACT_APP_WEB_STORAGE_TOKEN;
}





export { baseRoute, apiBaseUrl, localStorageToken }







