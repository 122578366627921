import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import EditCashOutModal from "../../Components/Modals/EditCashOutModal";
import { updateCashOut } from "../../features/cashOutSlice";
import {
  useGetAllCashOutQuery,
  useUpdateCashOutMutation,
} from "../../services/cashOutApi";

const CashOutRequestManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllCashOutQuery({ refetchOnMountOrArgChange: true });

  var cashOutData = useSelector((state) => state.cashOut.requests);

  useEffect(() => {
    setRowData(cashOutData);
  }, [cashOutData]);

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(updateCashOut(data.data));
    }
  }, [isSuccess, data, dispatch]);

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const [updateCashOutRequest] = useUpdateCashOutMutation();

  const handleUpdate = async (formData) => {
    const userSlug = editedData.slug;

    const modifiedData = {
      status: formData.status,
      slug: userSlug,
    };

    console.log(modifiedData);

    try {
      const { data } = await updateCashOutRequest(modifiedData);
      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    if (isLoading) {
      return <ShimmerTable row={5} col={7} />;
    }
    if (rowData.length === 0) {
      return "Record not found.";
    }
  }

  const columns = [
    {
      dataField: "account_name",
      text: "Account Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "account_number",
      text: "Account Number",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "amount", //todo:
      text: "Amount",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        return <span>$ {row.amount}</span>;
      },
    },
    {
      dataField: "snn_number",
      text: "SNN #",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "routing_number",
      text: "Routing #",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "120px",
      },
      formatter: (cellContent, row) => {
        if (row.status === "Paid")
          return <div className={styles.paid}> Paid </div>;
        if (row.status === "Pending")
          return <div className={styles.pending}> Pending </div>;
        if (row.status === "Rejected")
          return <div className={styles.pending}> Rejected </div>;
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              {
                <div
                  className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
                  role="button"
                  onClick={() => {
                    console.log(row);
                    setEditedData(row);
                    handleEditModalOpen(true);
                  }}
                >
                  <span
                    className={`align-items-center d-flex justify-content-center pe-1 `}
                  >
                    <FaUserEdit />
                  </span>
                  <span>Edit</span>
                </div>
              }
              {
                // <div
                //   className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                //   role="button"
                //   onClick={() => {
                //     setEditedData(row);
                //     handleDeleteModalOpen();
                //   }}
                // >
                //   <span className={`d-flex align-items-center pe-1`}>
                //     <MdDeleteForever />
                //   </span>
                //   <span>Delete</span>
                // </div>
              }
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {editModal ? (
        <EditCashOutModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleUpdate={handleUpdate}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Cash Out Request Management"} />
        </div>
        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add Event"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {
          // <Table data={rowData} columns={columns} indication={indication} />
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default CashOutRequestManagementScreen;
