import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionList: [],
};

export const subscriptionSlice = createSlice({
  name: "subscriptionInfo",
  initialState,
  reducers: {
    setSubscriptionList: (state, action) => {
      state.subscriptionList = action.payload;
    },
    unsetSubscriptionList: (state, action) => {
      state.subscriptionList = null;
    },
  },
});

export const { setSubscriptionList, unsetSubscriptionList } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
