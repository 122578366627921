import React from "react";
import { Navigate } from "react-router-dom";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";
import { getToken } from "../../services/localStorageService";

function ProtectedRoute({ children }) {

    // const token = GetTokenLocalStorage() //todo:
    // const token = false;
    const token = getToken();

    return (
        token ? children : <Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} replace />
    );
}

export default ProtectedRoute;