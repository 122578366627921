import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalPost: "",
  totalWinningPost: "",
  winningUser: "",
  totalEvents: "",
  totalPostLikes: "",
  totalUser: "",
};

export const dashboardSlice = createSlice({
  name: "dashboardInfo",
  initialState,
  reducers: {
    setDashboardInfo: (state, action) => {
      state.totalPost = action.payload.totalPost;
      state.totalWinningPost = action.payload.totalWinningPost;
      state.winningUser = action.payload.winningUser;
      state.totalEvents = action.payload.totalEvents;
      state.totalPostLikes = action.payload.totalPostLikes;
      state.totalUser = action.payload.totalUser;
    },
    unsetDashboardInfo: (state, action) => {
      state.totalPost = action.payload.totalPost;
      state.totalWinningPost = action.payload.totalWinningPost;
      state.winningUser = action.payload.winningUser;
      state.totalEvents = action.payload.totalEvents;
      state.totalPostLikes = action.payload.totalPostLikes;
      state.totalUser = action.payload.totalUser;
    },
  },
});

export const { setDashboardInfo, unsetDashboardInfo } = dashboardSlice.actions;

export default dashboardSlice.reducer;
