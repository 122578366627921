import React, { useState } from 'react'
import { DateTimePicker } from "@mui/x-date-pickers";
import './CustomDateTimePicker.scss';



const CustomDateTimePicker = ({ classes, value, onChange }) => {
    // console.log(value)
    return (
        <div>
            <DateTimePicker
                className={`datePicker ${classes}`}
                value={value}
                onChange={onChange}

            // defaultValue={dayjs('2022-04-17T15:30')}
            />
        </div>
    )
}

export default CustomDateTimePicker;
