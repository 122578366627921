import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import CustomDateTimePicker from "../CustomDateTimePicker/CustomDateTimePicker";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";

const EditEventModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  isCreating,
  isUpdating,
}) => {
  // const [rowId, setRowId] = useState("");
  // const [eventName, setEventName] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const statusList = [
    { label: "Active", value: true },
    { label: "Complete", value: false },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    console.log(data);
    console.log(data.status);
    if (data) {
      if (data.status === true) {
        setSelectedStatus("Active");
      } else {
        setSelectedStatus("Complete");
      }
    }
  }, [data, mounted]);

  // const handleEventName = (e) => {
  //   console.log(e.target.value);
  //   setEventName(e.target.value);
  // };

  const handleEventStartDate = (data) => {
    console.log(data);
    setEventStartDate(data);
  };

  const handleEventEndDate = (data) => {
    console.log(data);
    setEventEndDate(data);
    // let date = `${data.$y}-${data.$M + 1}-${data.$D} ${data.$H}:${data.$m}:${data.$s}`;
    // console.log(date);

    // setEventEndDate(date);
  };

  const handleStatusChange = (value) => {
    console.log(value);
    setSelectedStatus(value);
  };

  const handleSubmit = (e) => {
    if (data) {
      const modifiedData = {
        status: selectedStatus,
      };

      handleUpdate(modifiedData);
    } else {
      const modifiedData = {
        event_started_date: eventStartDate,
        event_end_date: eventEndDate,
      };

      handleAdd(modifiedData);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 secondary-text">
              {" "}
              {data ? "Edit Event" : "Add Event"}{" "}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            {
              //   <Row>
              //   <Col md={12}>
              //     <p className="m-0 black-text">
              //       Event Name
              //     </p>
              //     <Input
              //       classes="mt-2 mb-3"
              //       type="text"
              //       placeholder="Enter event name"
              //       value={eventName}
              //       onChange={handleEventName}
              //     />
              //   </Col>
              // </Row>
            }
            {data ? (
              ""
            ) : (
              <Row>
                <Col md={12}>
                  <p className="m-0 black-text">Event Start Date & Time</p>
                  <CustomDateTimePicker
                    classes="mt-2 mb-3"
                    value={eventStartDate}
                    onChange={handleEventStartDate}
                  />
                </Col>
              </Row>
            )}
            {data ? (
              ""
            ) : (
              <Row>
                <Col md={12}>
                  <p className="m-0 black-text">Event End Date & Time</p>
                  <CustomDateTimePicker
                    classes="mt-2 mb-3"
                    value={eventEndDate}
                    onChange={handleEventEndDate}
                  />
                </Col>
              </Row>
            )}
            {
              // data ? '' :
              // <Row>
              //   <Col md={12}>
              //     <p className="m-0 black-text" >
              //       User Email
              //     </p>
              //     <Input
              //       classes="mt-2 mb-3"
              //       type="email"
              //       placeholder="Enter user email"
              //       value={userEmail}
              //       onChange={handleUserEmail}
              //     />
              //   </Col>
              // </Row>
            }
            {
              // data ? '' :
              // <Row>
              //   <Col md={12}>
              //     <p className="m-0 black-text" >
              //       Password
              //     </p>
              //     <Input
              //       classes="mt-2 mb-3"
              //       type="password"
              //       placeholder="Enter password"
              //       value={password}
              //       onChange={handlePassword}
              //     />
              //   </Col>
              // </Row>
            }
            {data ? (
              <Row>
                <Col md={12}>
                  <p className="m-0 black-text">Status</p>
                  <SelectFieldComponent
                    firstOption={
                      _.isEmpty(statusList) ? "Select Status" : selectedStatus
                    }
                    optionsList={statusList}
                    onSelectChange={handleStatusChange}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
            {
              // <Row>
              //   <Col md={12}>
              //     <p className="m-0 black-text" >
              //       Status
              //     </p>
              //     <SelectFieldComponent
              //       firstOption={_.isEmpty(selectedStatus) ? "Select Status" : selectedStatus}
              //       optionsList={statusList}
              //       onSelectChange={handleStatusChange}
              //     />
              //   </Col>
              // </Row>
            }
            <Row>
              <Col md={12}>
                <Button
                  classes={`${cssStyles.cardBtn} mt-4  ${
                    isCreating || isUpdating ? "bg-danger-subtle" : ""
                  }`}
                  text={
                    isCreating || isUpdating ? (
                      <BeatLoader size={10} />
                    ) : data ? (
                      "Update"
                    ) : (
                      "Save"
                    )
                  }
                  onClick={handleSubmit}
                ></Button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditEventModal;
