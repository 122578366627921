import React from "react";
import { Description } from "../CustomComponents";

const UserAvatarWithNameAndTag = ({ avatarImage, userName, userTag }) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-end ">
      <div className="d-flex">
        <img
          className="image-circle rounded-5"
          src={avatarImage}
          alt=""
          width={50}
          height={50}
        />
        <div className="d-flex flex-column mx-2">
          <Description classes={`fw-bold black-text`} text={userName} />

          {userTag ? (
            <Description classes={`fs-8 black-text`} text={userTag} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserAvatarWithNameAndTag;
