import React, { useState } from 'react'

import { ROUTES } from "./Route";
import Login from '../Account/Login';
import PostManagementScreen from './PostManagementScreen';

export default function PostManagement() {

    const [page, setPage] = useState(ROUTES.POST_MANAGEMENT)

    switch (page) {
        case ROUTES.POST_MANAGEMENT:
            return <PostManagementScreen setPage={setPage} />
        default:
            return <Login />
    }


}
