import React, { useEffect, useState } from "react";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";
import { FaCircleXmark } from "react-icons/fa6";
import { Col, Form, Row } from "react-bootstrap";
import { Input, Button } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import _ from 'lodash';
import CustomDateTimePicker from "../CustomDateTimePicker/CustomDateTimePicker";
import { Controller, useForm } from "react-hook-form";

const EditCashOutModal = ({ handleEditModalClose, data, handleUpdate }) => {
  const { handleSubmit, control } = useForm({});

  const [slug, setSlug] = useState("");

  const [selectedStatus, setSelectedStatus] = useState('');

  const statusList = [
    { label: "Pending", value: "Pending" },
    { label: "Paid", value: "Paid" },
    { label: "Rejected", value: "Rejected" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    console.log(data)
    if (data) {
      console.log(data.status)
      if (data.status === 'Paid') {
        setSelectedStatus("Paid");
      }
      if (data.status === 'Pending') {
        setSelectedStatus("Pending");
      }
      if (data.status === 'Rejected') {
        setSelectedStatus("Rejected");
      }
    }

  }, [data, mounted]);

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 secondary-text"> {data ? "Edit Check Out Status" : "Add Event"} </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100 black-text">
            <p>Account Name: <b> {data.account_name}  </b></p>
            <p>Account #: <b> {data.account_number} </b> </p>
            <p>Amount: <b> {data.amount} </b> </p>
            <p>SNN #: <b> {data.snn_number} </b> </p>
            <p>Routing #: <b> {data.routing_number} </b> </p>


            <Form onSubmit={handleSubmit(handleUpdate)}>
              <Row>
                <Col md={12}>
                  <p className="m-0 black-text" >
                    Status
                  </p>
                  <Controller
                    control={control}
                    name="status"
                    rules={{}}
                    defaultValue={selectedStatus}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <SelectFieldComponent
                        firstOption={
                          _.isEmpty(selectedStatus)
                            ? "Select Status"
                            : selectedStatus
                        }
                        optionsList={statusList}
                        onSelectChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 `}
                    text={data ? "Update" : "Save"}
                    // onClick={handleSubmit}
                    type="submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditCashOutModal;
