import React, { useMemo } from 'react';

import styles from './styles/Menu.module.scss';
import { RiLockPasswordFill } from "react-icons/ri";
import { SiGoogledocs } from "react-icons/si";
import { RiSettings3Fill } from "react-icons/ri";
import { MdMarkEmailRead } from "react-icons/md";

import { Description, SubTitle } from '../../Components/CustomComponents';
import { ROUTES } from './contants';

export default function Menu({ route, setRoute }) {
    const OPTIONS = useMemo(() => {
        return [
            // {
            //     label: 'Edit Profile',
            //     route: ROUTES.EDIT_PROFILE,
            //     icon: <EditIcon />
            // },
            {
                label: 'Change Password',
                route: ROUTES.CHANGE_PASSWORD,
                icon: <RiLockPasswordFill size={20} />
            },
            {
                label: 'Terms And Conditions',
                route: ROUTES.TERMS_CONDITIONS,
                icon: <SiGoogledocs size={20} />
            },
            {
                label: 'Privacy Policy',
                route: ROUTES.PRIVACY_POLICY,
                icon: <SiGoogledocs size={20} />
            },
            {
                label: 'Contact Us',
                route: ROUTES.CONTACT_US,
                icon: <MdMarkEmailRead size={20} />
            },
            {
                label: 'Post Setting',
                route: ROUTES.POST_SETTING,
                icon: <RiSettings3Fill size={20} />
            },
        ]
    }, [])

    const handleMenuItemClick = (route) => {
        setRoute(route)
    }

    return (
        <div className={styles.Menu}>
            <SubTitle
                text='Setting'
            />
            <Description
                text='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                classes='mt-1 mb-4 fs-8'
            />
            {OPTIONS.map((item, index) => {
                return (
                    <div
                        className={`${styles.option} my-3 ${(route === item.route) ? styles.active : ''}`}
                        key={index}
                        onClick={() => handleMenuItemClick(item.route)}

                    >
                        <span className={styles.icon}>{item?.icon}</span>
                        <Description
                            text={item.label}
                            classes='fs-7'
                        />
                    </div>
                )
            })}
        </div>
    )
}