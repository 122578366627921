import React, { useState } from "react";

import { ROUTES } from "./Route";
import Login from "../Account/Login";
import EventManagementScreen from "./EventManagementScreen.js";

export default function UserManagement() {
  const [page, setPage] = useState(ROUTES.EVENT_MANAGEMENT);

  switch (page) {
    case ROUTES.EVENT_MANAGEMENT:
      return <EventManagementScreen setPage={setPage} />;
    default:
      return <Login />;
  }
}
